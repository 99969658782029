
.createcard_input_container_new.input_small input[type="text"], 
.createcard_input_container_new.input_small input[type="number"], 
.createcard_input_container_new.input_small input[type="color"],
.createcard_input_container_new.input_small select {
    height: 100% !important;
    flex: 1 !important;
    min-width: 0px !important;
    border: none !important;
    font-size: 12px !important;
    outline: none !important;
    padding: 0px !important;
}

.input_small{
    height: 22px !important;
}

.createcard_input_container_new{
    background-color: #ffffff !important;
    border: 0.6px solid #CED4D9 !important;
    border-radius: 2px !important;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0px 4px !important;
    gap: 4px;
    max-width: 100%;
    width: -webkit-fill-available;
    flex: 1;
}

.createcard_input_container_new:focus-within{
    border: 1px solid #0078ff !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container_new.accept:focus-within{
    border: 1px solid #23C16B !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container_new.reject:focus-within{
    border: 1px solid var(--red_default) !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}


.createcard_input_container{
    background-color: #ffffff;
    border: 1px solid #CED4D9;
    border-radius: 2px;
}

.createcard_input_container:focus-within{
    border: 1px solid #0078ff !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container.accept:focus-within{
    border: 1px solid #23C16B !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container.reject:focus-within{
    border: 1px solid var(--red_default) !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.panel_flex_property_wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 4px 12px;
}

.panel_section{
    --panel_section_item_gap_x: 8px;
    --panel_section_item_padding_x: 0px;
    background-color: transparent;
    text-align: left;
    /* padding: 4px 8px 4px 8px; */
    padding: 10px 8px 10px 8px;
    border-bottom: 1px solid #E6E9EE;
    position: relative;
    line-height: normal;
}

/* .panel_section .panel_section:last-child{
    border-bottom:none;
} */

.panel_section_menu_item:last-child .panel_section{
    border: none;
}

.panel_section_menu_item:last-child .panel_sub_section{
    padding: 0px;
}

.panel_card{
    padding: 0px var(--panel_section_item_padding_x);
}

/* .panel_section::after{
    content: '';
    height: 1px;
    width: 270px;
    position: absolute;
    background-color: #000;
    bottom: 0px;
    left: 0px;
    margin: 0px -8px;
} */

/* .panel_section .panel_section{
    padding: 4px 0px;
} */

.panel_section_menu, .panel_sub_section_menu{ 
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 8px var(--panel_section_item_gap_x, 8px) ;
}

.panel_sub_section_menu{
    /* padding: 0px 0px 0px 4px; */
    --panel_section_item_gap_x: 8px;
}

.panel_section_menu{
    padding: 0px 4px 0px 12px;
}

.panel_sub_section {
    padding-bottom: 8px;
}

.panel_section_header, .panel_sub_section_header{
    padding: 0px var(--panel_section_item_padding_x);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.panel_section_header{
    margin-bottom: 12px;
    cursor: pointer;
}

.panel_sub_section_header{
    margin-bottom: 6px;
}

.panel_section_header.collapsed{
    margin-bottom: 0px;
}

.panel_section_header::before{
    content: '▾';
    margin-right: 4px;
    line-height: 1;
}

.panel_section_header.panel_empty_submenu::before{
    opacity: 0.5;
}

.panel_section_header.collapsed::before{
    content: '▸';
    margin-right: 4px;
    line-height: 1;
}

.panel_section_heading_text{
    /* text-decoration: underline; */
    color: #323338;
    line-height: normal;
    font-style: italic;
    font-weight: 600;
    font-size: 13px;
    flex: 1;
    min-width: 0px;
}

.panel_section_heading_text:hover{
    color: #000;
}

.panel_section_header.panel_empty_submenu .panel_section_heading_text{
    opacity: 0.5;
}

.panel_sub_section_heading_text{
    color: #323338;
    /* text-decoration: underline; */
    line-height: normal;
    /* font-style: italic; */
    font-weight: 500;
    flex: 1;
    min-width: 0px;
}

.panel_section_header_2{
    padding-top: 2px;
    padding-bottom: 4px;
    text-decoration: underline;
    line-height: normal;
    font-style: italic;
    font-weight: 500
}

.panel_selectable_button{
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.panel_selectable_button.active{
    background: #E6E9EE;
}

.panel_selectable_button:hover{
    background: #E6E9EE80;
}

.material_selector_new_buttons{
    display: flex;
    gap: 6px;
    /* align-self: end; */
    height: 22px;
    align-items: center;
    /* visibility: hidden; */
    /* opacity: 0; */
}

.material_selector_new_buttons .infurnia_icon {
    opacity: 0;
    cursor: pointer;
}

.material_select_new:hover .material_selector_new_buttons .infurnia_icon{
    /* visibility: visible; */
    opacity: 1;
}

.material_selector_new_buttons .infurnia_icon.option_dropdown_open{
    /* visibility: visible; */
    opacity: 1;
}

.material_selector_new_buttons > * {
    cursor: pointer;
}

.panel_wrap_container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.panel_multi_component_input_container {
    width: 100%;
    border: 1px solid ;
    display: flex;
}

.panel_multi_component_single_input_container{
    flex:1;
    min-width: 0;
    display: flex;
}

.panel_multi_component_single_input_container > * {
    flex: 1;
    min-width: 0;
}

.panel_multi_component_single_input_container > input,select {
    border: none;
}


.show_on_list_item_hover{
    display: none;
}

.panel_checkbox_input_new:hover{
    color: #0078ff;
}

.panel_checkbox_input_new{
    cursor: pointer;
}

.panel_list_item_new{
    /* padding: 4px 0px; */
}

.panel_list_item_new:hover{
    color: #0078ff;
}

.panel_list_item_new.danger:hover{
    color: #FF5247;
}

.panel_list_item_new:hover .show_on_list_item_hover{
    display: block;
}

/* Rotation input */
.RI----------RI{
    background-color: transparent;
}

/* Intensity input */
.II----------II{
    background-color: transparent;
    width: 100%;
    padding: 15px 0px 2px 0px;
}

/* Select Input */
.SI----------SI{
    background-color: transparent;
}

/* Dimension Input */
.dI----------dI{
    background-color: transparent;
}

/* List Item */
.LI----------LI{
    background-color: transparent;
    /* height: 36px; */
    font-weight: 500;
}

/* Text Input */
.TI----------TI{
    background-color: transparent;
}

/* Visibility Input */
.VI----------VI{
    background-color: transparent;
}

/* Color Picker */
.CP----------CP{
    background-color: transparent;
}

/* Material Select */
.MS----------MS{
    background-color: transparent;
}

/* Composite Select */
.CS----------CS{
    background-color: transparent;
}

/* Checkbox */
.CB----------CB{
    background-color: transparent;
}

/* Text Info */
.TI----------TI{
    background-color: transparent;
}

/* Select and Dimension Input */
.SDI----------SDI{
    background-color: transparent;
}

/* Toggle */
.TG----------TG{
    background-color: transparent;
}

/* Button Array */
.BA----------BA{
    background-color: transparent;
}

/* Top Level Toolbar */
.TT----------TT{
    background-color: transparent;
}

.CP----------CP .createcard_input_container input{
    padding: 0 !important;
}

.SI----------SI .createcard_input_container{
    padding-right: 6px !important;
}

.PS----------PS:hover{
    background-color: #F5F6F8;
}

.PS----------PS:hover .show_on_hover_sheets{
    visibility: visible;
}

.PS----------PS:not(:hover) .show_on_hover_sheets:not(:focus){
    visibility: hidden;
}

.show_on_hover_sheets:focus {
    visibility: visible;
    background: #E6E9EE !important;
}

.options_font {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #676878;
}

.PS----------PS{
    width: 100%;
    margin: 0;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0px 12px;
}

.CT----------CT{
    position: unset;
}

.TT----------TT{
    position: unset;
}

.dropdown_heading_panel{
    font-size: 12px;
    font-weight: 500;
}

.section_heading_panel{
    font-size: 12px;
    font-weight: 500;
    color: #676878;
}

.dropdown_panel{
    height: 36px;
    cursor: pointer;
}

.active_top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid #3D434D;
    border-right: 1px solid #6c7789;
    background-color: #616B7A;
    cursor: pointer;
}

.active_top_tab_panel_search{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    /* border-bottom: 3px solid #3D434D;
    border-right: 1px solid #6c7789; */
    background-color: white;
    cursor: pointer;
}

.top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789;
    cursor: pointer;
}

.top_tab_panel:hover{
    border-bottom: 3px solid #616B7A;
}

.LI----------LI:hover .list_item_blue_hover{
    color: #0078ff !important;
}

.panel_card:hover .list_item_blue_hover{
    color: #0078ff !important;
}

.list_item_visible_hover{
    visibility: hidden;
}

.LI----------LI:hover .list_item_visible_hover{
    visibility: visible;
}

.button_panel{
    align-items: center;
    cursor: pointer;
}

.overriden_layer_item {
    background: #FFFAE6;
}

.overriden_layer_item:hover {
    background: #F5F2E6;
}

.normal_layer_item:hover {
    background: #F5F6F8;
}

.disable_feedback:hover {
    background-color: unset !important;
}

.tab_index_panel{
    font-size: 12px;
    padding: 0px 6px;
    border-right: 1px solid #CED4D9;
    display: flex;
    align-items: center;
    color: #a7a8b2;
    min-width: 32px;
    justify-content: center;
}

.disabled_top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789;
    cursor:not-allowed;
    opacity: 0.5;
}

.top_name_panel{
    height: 40px;
    /* width: 40px; */
    align-items: center;
    /* justify-content: center; */
    display: flex;
    /* border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789; */
    cursor: pointer;
    padding-left: 8px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.rename_group_input_container{
    width: 100%;
    padding: 8px;
    font-size: 12px;
    height: 40px;
}

.rename_group{
    /* all: unset; */
    height: 100%;
    font-size: 12px;
    border-radius: 2px;
}

.pencil-svg path,
.pencil-svg line,
.pencil-svg polyline,
.pencil-svg rect,
.pencil-svg circle {
  /* stroke: black; */
  fill: black;
}
#panel_search_input{
    /* flex: 1; */
    margin: 0;
    width: calc( 100% - 90px );
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 6px;
    height: 12px;
    background:  #676878;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #eef2f0;
    cursor: pointer;
  }
  

  /* comments */
  .comment-input {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #0078ff;
      max-height: 200px;
      overflow: auto;
      background-color: white;
  }

  .comment-input textarea {
      flex: 1;
      min-height: 36px;
      padding: 0px;
      border: none;
      outline: none;
      resize: vertical;
      height: auto;
      overflow: visible;
  }

  .comment-input button {
      padding: 8px 12px;
      margin-left: 8px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }

  .comment-input button:hover {
      background-color: #0056b3;
  }

  .suggestion-list{
    min-width: 125px;
    text-align: left;
    font-size: 12px;
    color: black;
    padding: 4px;
    cursor: pointer;
    margin-top: 8px;
    margin-left: 8px;
    border: 1px solid #0078ff;
  }

  .single-suggestion{
    height: 28px;
    display: flex;
    align-items: center;
  }

  .single-suggestion:hover{
    background-color: rgba(24,144,255,.06);
  }

.inf-presets-color-picker {
    display: flex;
    justify-content: center;
}

.inf-presets-color-grid {
    display: grid;
    grid-template-columns: repeat(12, 12px);
    /* Adjust column count as needed */
    grid-gap: 5px;
}

.inf-presets-color-option {
    width: 12px;
    height: 12px;
    cursor: pointer;
    border: 1px solid var(--light-border-color);
    border-radius: 1px;
}

.inf-presets-color-option.selected {
    border: 1px solid #0078ff;

}

.image_map_select_dropdown {
    width: calc( var(--panel_width) - 16px );
    max-height: 240px;
    overflow-y: auto;
    position: absolute !important;
    margin-top: 42px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.5);
    /* left:0 !important; 
    top:40px !important; */
}

.sheet-dropdown-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin: 6px 10px;
  }

  /* .panel_numeric_integer_input:focus {
    color: black;
  } */
  
  .panel_numeric_integer_input:not(:focus-within)::after {
    content: attr(data-decimal);
    color: gray;
    display: inline-block;
    /* background-color: #0078ff; */
    font-size: 12px;
    opacity: 1;
  }
  
  .createcard_input_container::-webkit-scrollbar{
    height: 0px;
  }

  .panel_button_array > :not(:last-child){
    margin-right: 8px;
  }

.panel_tree_parent_header{
    padding: 8px 12px;
}

.panel_tree_leaf{
    padding: 8px 12px;
}

.panel_tree_leaf_indicator{
    /* margin-left: 4px; */
    transform: translateX(5px);
    /* display: inline-block; */
    position: relative;
}
.panel_tree_leaf_indicator::before{
    /* border: 1px solid; */
    /* height: 20px; */
    content: '';
    top: 0px;
    height: 36px;
    position: absolute;
    border-inline-end: 1px solid var(--ant-color-border, #000);
}
.panel_tree_leaf_indicator::after{
    content: '';
    content: '';
    top: 0px;
    height: 18px;
    width: 4px;
    position: absolute;
    border-bottom: 1px solid var(--ant-color-border, #000);
}

.panel_tree_parent_collapse > .panel_tree_item_container.last_tree_item > .panel_tree_leaf_indicator_container > .panel_tree_leaf_indicator::before{
    height: 18px !important;
}

.panel_tree_parent_collapse > .panel_tree_collapse_indicator_container > .panel_tree_collapse_indicator::before{
    height: 100%;
    content: '';
    position: absolute;
    border-right: 1px solid;
    transform: translateX(-7px);
}

.panel_tree_item{
    padding: 4px 8px;
    border-radius: 4px;
    flex: 1;
    min-width: 40px;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.panel_tree_item:hover{
    background-color: rgba(54, 134, 247, 0.1);
    color: #0078ff;
}

.root_tree_item{
    padding: 4px 8px 4px 0px;
    border-radius: 4px;
    flex: 1;
    min-width: 40px;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.BI----------BI.highlight_and_fade_bi{
    animation: blink-box-shadow 1.5s 2 ease-in-out;
}

@keyframes blink-box-shadow {
    25% {box-shadow: inset 0px 0px 0px 2px #0078FF80;}
    75% {box-shadow: inset 0px 0px 0px 2px #0078FF80;}
}

.edge_band_options_dropdown_toggle{
    color: black;
    font-size: 12px;
    background-color: #E6E9EE80;
    padding: 0px 4px;
    border-radius: 2px;
    white-space: nowrap;
}

.edge_band_options_dropdown_toggle_text{
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.panel_edgeband_item{
    cursor: pointer;
    border: 1px solid #323338;
    border-radius: 2px;
    /* background-color: yellow; */
    
}

.panel_edgeband_item.no_edge_band{
    border: 1px dashed #A7A8B2;
}

.panel_edgeband_item.selected{
    border: 2px solid #0078ff;
}

.panel_edgeband_item.selected:hover{
    border: 2px solid #0078ff;
}

.panel_edgeband_item.left.selected::before{
    content: '';
    position: absolute;
    height: 2px;
    background: #0078ff;
    left: calc(var(--panel_edgeband_item_thickness) - 2px);
    width: 16px;
    top: 50%;
}

.panel_edgeband_item.left>div{
    max-width: calc(0.8*var(--panel_edgeband_visualizer_height) - 1.6*var(--panel_edgeband_item_gap_y));
    transform: rotate(270deg);
}

.panel_edgeband_item.right>div{
    max-width: calc(0.8*var(--panel_edgeband_visualizer_height) - 1.6*var(--panel_edgeband_item_gap_y));
    transform: rotate(270deg);
}

.panel_edgeband_item.front>div{
    max-width: 80%;
}

.panel_edgeband_item.back>div{
    max-width: 80%;
}

.panel_edgeband_item.right.selected::before{
    content: '';
    position: absolute;
    height: 2px;
    background: #0078ff;
    right: calc(var(--panel_edgeband_item_thickness) - 2px);
    width: 16px;
    top: 50%;
}

.panel_edgeband_item.front.selected::before{
    content: '';
    position: absolute;
    width: 2px;
    background: #0078ff;
    top: calc(var(--panel_edgeband_item_thickness) - 2px);
    height: 16px;
    left: 50%;
}

.panel_edgeband_item.back.selected::before{
    content: '';
    position: absolute;
    width: 2px;
    background: #0078ff;
    bottom: calc(var(--panel_edgeband_item_thickness) - 2px);
    height: 16px;
    left: 50%;
}

.panel_edgeband_item.add_edge_band{
    border: 1px dashed #A7A8B2;
    background-color: #F5F6F8;
    background-image: url('/resources/nav_icons_new/add_icon.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 14px;
}

/* .panel_edgeband_item.invisible_edge_band::after{
    content: '';
    position: absolute;
    background: repeating-linear-gradient(
                Angle of the diagonal lines
                135deg, 
                Color of the slashes
                #f0f0f0, 
                End color of the slashes
                #f0f0f0 2px, 
                Start color of the background
                transparent 2px, 
                End color of the background
                transparent 8px 
            );
    width: 100%;
    height: 100%;
    
} */

.panel_edgeband_item:hover{
    border: 1px solid #0078ff;
}

.panel_edgeband_visualiser{
    --panel_edgeband_item_thickness: 24px;
    --panel_edgeband_visualizer_height: 108px;
    --panel_edgeband_item_gap_x: 32px;
    --panel_edgeband_item_gap_y: calc( var(--panel_edgeband_item_thickness) / 2 );

    width: 100%;
    position: relative;
    height: var(--panel_edgeband_visualizer_height, 108px);
}

.panel_edgeband_info_text{
    padding: 4px;
    color: #A7A8B2
}

.panel_edgeband_actions_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #F5F6F8;
    width: calc(100% - var(--panel_edgeband_item_gap_x) - var(--panel_edgeband_item_gap_x));
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    border: 2px solid #0078ff;
}